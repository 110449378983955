import React from 'react';
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { css } from '@emotion/core';
import { rhythm } from '../styles/util';

const style = css`
  display: flex;
  margin: ${rhythm(1)} 0;
  > * { flex-grow: 1; }

  img {
    transition: filter .4s !important;

    &:hover { filter: brightness(1.1) }
  }

  @media (max-width: 900px) {
    > :nth-of-type(n+5) { display: none; }
  }

  @media (max-width: 500px) {
    > :nth-of-type(n+3) { display: none; }
  }
`;

const InstaFeed = () => {
  const data = useStaticQuery(graphql`
    query InstagramQuery {
      allInstagramPost {
        edges {
          node {
            id
            caption
            permalink
            image {
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);
  const images = data.allInstagramPost.edges;

  return (
    <div css={style} n={{2: '0px', 3: '500px', 6: '900px'}}>
      {images.map(({node: {id, caption, permalink, image}}) => 
        <a key={id} target="_blank" href={permalink}>
          <Img fluid={image.childImageSharp.fluid} alt={caption} />
        </a>)}
    </div>
  );
};

export default InstaFeed;