import React, {useState} from 'react'
import {css, keyframes, Global} from '@emotion/core'
import styled from '@emotion/styled'
import { Link } from "gatsby";
import { colors, MQ } from 'src/styles/constants'
import { rhythm } from 'src/styles/util'
import logo from 'src/images/logo.png'
import Menu from './Menu';
import MenuToggle from './MenuToggle';

const headerStyle = css`
  position: relative;
  padding: ${rhythm(1)} 0;
`

const twist = keyframes`
  0%, 100% { transform: rotate(0deg) scale(1); }

  35% { transform: rotate(-10deg) scale(1.03); }
  75% { transform: rotate(10deg) scale(1.02); }
`

const logoDiameter = '180px';
const logoDiameterSm = '120px';

const logoStyle = css`
  width: ${logoDiameter};
  height: ${logoDiameter};
  display: block;
  position: relative;
  margin: 0 auto;
  z-index: 100;

  &:hover {
    animation: ${twist} .6s ease;
  }

  ${MQ.SM} {
    width: ${logoDiameterSm};
    height: ${logoDiameterSm};
  }
`

const openMenuGlobalStyle = css`
  ${MQ.MD} {
    main, footer { height: 0; overflow: hidden; }
  }
`;

const Header = ({ siteTitle }) => {
  const [menuVisible, setMenuVisible] = useState(false);

  return (
    <header css={headerStyle}>
      <Link to="/">
        <img src={logo} alt={`${siteTitle} logo`} css={logoStyle} />
      </Link>

      <MenuToggle active={menuVisible} onToggle={() => setMenuVisible(menuVisible => !menuVisible)} />

      <Menu active={menuVisible} /> 

      {/* Lock body scrolling when menu open */}
      {menuVisible && <Global styles={openMenuGlobalStyle} />} 
    </header>
  )
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
