import React from 'react';
import {rhythm} from 'src/styles/util';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { colors } from '../styles/constants';
import Columns from './Columns';
import Subscribe from './Subscribe';
import { FaFacebook, FaGoogle, FaYoutube, FaInstagram, FaEnvelope } from 'react-icons/fa';

const IconWrap = styled.div`
  > a { margin-left: ${rhythm(1/2)}; color: ${colors.light}; box-shadow: none !important; }
`

const style = css`
  padding: ${rhythm(1)};
  background: ${colors.hi1};
  color: ${colors.light};
  text-align: center;
  border-top: 2px solid ${colors.dark};
`
const Footer = () => (
  <footer css={style}>    
    <Columns n={{2: '800px'}} vAlign='center'>
      <Subscribe />
      
      <IconWrap>
        <a target="_blank" href="https://www.facebook.com/foxcreekfarmcsa/"><FaFacebook title="Facebook" size="2em" /></a>
        <a target="_blank" href="https://www.google.com/maps/place/Fox+Creek+Farm+CSA/@42.677627,-74.229578,15z/data=!4m5!3m4!1s0x0:0xfbe06d970f2f6f0!8m2!3d42.677627!4d-74.229578"><FaGoogle title="Google Business" size="2em" /></a>
        {/* <FaYoutube title="Youtube" size="2.1em" /> */}
        <a target="_blank" href="https://instagram.com/foxcreekfarmcsa/"><FaInstagram title="Instagram" size="2em" /></a>
        <a href="mailto:farmer@foxcreekfarmcsa.com"><FaEnvelope title="Email" size="2em" /></a>
      </IconWrap>
    </Columns>

    <p> © {new Date().getFullYear()} Fox Creek Farm CSA </p>
  </footer>
);

/**
 * copyright notice
 * social icons
 * instagram feed
 * organic logo
*/


export default Footer;