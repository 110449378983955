import React from 'react'
import {css, keyframes} from '@emotion/core'
import styled from '@emotion/styled'
import { Link } from 'gatsby';
import { colors, MQ } from 'src/styles/constants'
import { rhythm, setFontSize, fadeIn } from 'src/styles/util'
import menu from '../../content/settings/menu.yml';
import { scrollShadowVertical } from '../styles/util';

const List = styled.ul`
  position: absolute;
  left: 0; right: 0; top: 0; bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
  margin: 0; padding: 0 ${rhythm(1/2)};
  list-style: none;

  > li {
    text-align: center;
    position: relative;
    padding: 20px 0;

    @media (min-width: 950px) { flex-grow: .1; }
    ${MQ.MD} { text-align: left; }
  }

  a {
    padding: 0 ${rhythm(1/2)};
    color: ${colors.dark};
    text-decoration: none;
    box-shadow: none !important;
    transition: .2s ease;

    &:hover { color: ${colors.hi2}; }

    ${MQ.MD} {
      ${setFontSize(110, 1.5)}
    }
  }

  > li ul {
    display: none;
    position: absolute;
    top: calc(50% + ${rhythm(3/4)});
    left: 50%;
    transform: translateX(-50%);
    list-style: none;
    padding: 0; margin: 0;
    box-shadow: 0 0 6px -4px ${colors.dark};
    border-radius: 4px;
    min-width: 75px;
    white-space: nowrap;
    padding: ${rhythm(1/4)} 0;

    &:before {
      content: '';
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 5px solid ${colors.hi1};
      position: absolute;
      bottom: 100%;
      left: calc(50% - 8px);
    }
  }

  >li:hover ul { display: block; animation: .4s ${fadeIn}; }
  
  ${MQ.MD} {
    display: ${p => p.active ? 'block' : 'none'};
    position: absolute;
    top: 100%; left: 0; bottom: auto;
    padding-bottom: ${rhythm(1/2)};
    background: ${colors.light};
    z-index: 50;

    > li {
      padding: 0;
    }

    > li ul {
      display: block;
      animation: none !important;
      box-shadow: none;
      position: static;
      transform: none;
      &:before { content: none; }
      padding-left: ${rhythm(1)}
    }
  }

`

const Spacer = styled.li`
  flex-grow: 1 !important;
`

const Items = ({items}) => (
  <>
    {items.map(({name, path, submenu}) =>
      <li key={name}>
        <Link to={path}>{name}</Link>
        {submenu && 
          <ul><Items items={submenu} /></ul>}
      </li>)}
  </>
)

const Menu = ({active}) => (
  <List active={active}>
    <Items items={menu.left} />

    <Spacer />

    <Items items={menu.right} />
  </List>  
)

export default Menu;