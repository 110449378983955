import React from 'react'
import {css} from '@emotion/core'
import { colors, MQ } from 'src/styles/constants'
import {rhythm} from 'src/styles/util'


const MenuToggle = ({active, onToggle}) => (
  <a 
    onClick={onToggle}
    role="button" 
    css={css`
      display: none;
      width: 30px; height: 24px;
      position: absolute;
      left: ${rhythm(1)};
      top: 0; bottom: 0;
      margin: auto 0;
      z-index: 100;
      cursor: pointer;
      box-shadow: none !important;

      > div {
        position: absolute;
        width: 100%; height: 2px;
        background: ${colors.dark};
        transition: transform .3s ease;
      }

      ${MQ.MD} {
        display: block;
      }
    `}
  >
    <div css={css`
      top: 0;
      ${active && `transform: translateY(11.5px) rotate(45deg);`}
    `}/>
    <div css={css`
      top: 50%;
      margin-top: -1px;
      transition: opacity .3s ease;
      ${active && `opacity: 0`}
    `} />
    <div css={css`
      bottom: 0;
      ${active && `transform: translateY(-11px) rotate(-45deg);`}
    `}/>

  </a>
)

export default MenuToggle;