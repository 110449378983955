import {css} from '@emotion/core';

import {fontFamily, fontSize, lineHeight, colors, MQ} from './constants'
import {rhythm, setFontSize} from './util'

const headingSizes = (min, max) => {
  const diff = max - min;
  const scales = [ 0, .15, .25, .40, .55, 1 ].reverse();
  const rules = {};

  for (let i = 0; i < 6; i++)
    rules['h' + (i+1)] = setFontSize(Math.round(min + diff * scales[i]));

  return rules;
}

const global = css`
  * { box-sizing: border-box; }
  
  html {
    font-size: ${fontSize};
    line-height: ${lineHeight};
    font-family: ${fontFamily};
  }

  body { 
    margin: 0;
    width: 100%;
  }

  ${headingSizes(110, 230)}

  h1, h2, h3, h4, h5, h6 {
    font-family: "Crafter", sans-serif;
    font-weight: normal;
    letter-spacing: .02em;
    color: ${colors.hi1};
    word-wrap: break-word;
  }

  h1 { text-align: center; }

  p, h1, h2, h3, h4, h5, h6, ul, ol, blockquote {
    margin: ${rhythm(1)} 0;
  }

  input[type="checkbox"], input[type="radio"] { position: relative; top: -2px; }

  a {
    color: ${colors.hi2};
    transition: .3s;
    text-decoration: none;
    &:hover { box-shadow: 0 1px 0 ${colors.hi2}; }
  }

  /* Heading resizes */
  ${MQ.SM} {
    ${headingSizes(105, 190)}
  }
  ${MQ.XS} {
    ${headingSizes(105, 170)}
  }
`

export default global;