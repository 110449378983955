import React from 'react';
import Button from './Button';
import {css} from '@emotion/core';
import Input from './Input';
import {oneLineFormStyle} from 'src/styles/util'

const Subscribe = (props) => (
  <form css={oneLineFormStyle} action="https://foxcreekfarmcsa.us16.list-manage.com/subscribe/post?u=ef8ea1924a1b9846bfb064314&amp;id=ab0623ae2d" method="post" id="subscribe-form" name="mc-embedded-subscribe-form" target="_blank" noValidate>
    {/*  <h2>Mailing list</h2>  */}
    <Input type="email" placeholder="email address" name="EMAIL" />

    {/*  Real people should not fill this in */}
    <div css={css`position: absolute; left: -5000px;`} aria-hidden="true">
      <input type="text" name="b_3081a2b808ee4dde89e725907_6eb796eec8" tabIndex="-1" />
    </div>

    <Button secondary title="Subscribe to our newsletter">Subscribe</Button>
  </form>
);

export default Subscribe;