import styled from '@emotion/styled';
import {rhythm, setFontSize} from 'src/styles/util';
import {colors} from 'src/styles/constants';

const Input = styled.input`
  padding: 0 ${rhythm(1/4)};
  border: ${p => p.border ? '1px solid' : 0};
  font-family: inherit;
  ${setFontSize(100, 1.25)}
  border-radius: 2px;
`

export default Input;