export const fontFamily = '"Bitter", serif';
export const fontSize = '110%';
export const lineHeight = '1.6';

export const colors = {
  dark: 'black',
  light: 'white',
  hi1: '#7a0000', //'#ef5820',
  hi2: '#a0bc94', //'#67ac37'
}

const MQ = {
  XS: 350,
  SM: 450,
  MS: 650,
  MD: 850,
  gt: {}
}

for (let size in MQ) {
  const val = MQ[size];
  if (typeof val !== 'number') continue;
  MQ.gt[size] = `@media (min-width: ${val+1}px)`;
  MQ[size] = `@media (max-width: ${val}px)`;
}

export {MQ}

