import styled from '@emotion/styled';
import {rhythm, setFontSize} from 'src/styles/util';
import {colors} from 'src/styles/constants';
import { Link } from 'gatsby';

function getColors(props) {
  let fg = colors.dark, bg = colors.light;

  if (props.primary) {
    bg = colors.hi1;
    fg = colors.light;
  } else if (props.secondary) {
    bg = colors.hi2;
  }

  return { color: fg, background: bg };
}

const Button = styled.button`
  display: inline-block;
  padding: 0 ${rhythm(1/2)};
  ${getColors}
  border: 0;
  border-radius: 8px;
  font-family: inherit;
  ${setFontSize(100, 1.25)}
  transition: .2s;

  :not(:disabled) {
    cursor: pointer;
    &:hover { box-shadow: 0 2px 4px -2px rgba(0,0,0,.7); }
  }

  :disabled {
    opacity: .5;
    cursor: not-allowed;
  }
`

export default Button;

export const ButtonLink = Button.withComponent(Link);
